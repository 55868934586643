// export const loginAction = (payload) => {
//   return {
//     type: 'LOGIN',
//     payload
//   };
// };

export const treeViewAction = (payload) => {
  return {
    type: 'TREEVIEWDETAILS',
    payload
  };
};

export const shortcutKeyCombinationAction = (payload) => {
  return {
    type: 'SHORTCUTKEYLIST',
    payload
  };
};

export const advocateDetailsErrorAction = (payload) => {
  return {
    type: 'ADVOCATEDETAILSERROR',
    payload
  };
};

export const advocateDataAction = (payload) => {
  return {
    type: 'ADVOCATEDATA',
    payload
  };
};

export const advocateDetailsAction = (payload) => {
  return {
    type: 'ADVOCATEDETAILS',
    payload
  };
};

export const formChangedAction = (payload) => {
  return {
    type: 'FORMCHANGED',
    payload
  };
};

export const tabInfoAction = (payload) => {
  return {
    type: 'TABINFO',
    payload
  };
};

export const selectedProductsAction = (payload) => {
  return {
    type: 'SELECTEDPRODUCTS',
    payload
  };
};







