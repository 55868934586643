import {
    ADVOCATE_REGISTRATION_REQUEST,
    ADVOCATE_REGISTRATION_SUCCESS,
    ADVOCATE_REGISTRATION_FAILURE,
    UPDATE_ADVOCATE_DETAIL_REQUEST,
    UPDATE_ADVOCATE_DETAIL_SUCCESS,
    UPDATE_ADVOCATE_DETAIL_FAILURE,
    ADVOCATE_LIST_REQUEST,
    ADVOCATE_LIST_SUCCESS,
    ADVOCATE_LIST_FAILURE
} from "../../actions/AdvocateRegistration/advocateRegistrationAction";
const initialState = {
    data: null,
    transactionDetails: [],
    advocateList: []
};

export default function advocateRegistrationReducer(state = initialState, action) {
    switch (action.type) {
        case ADVOCATE_REGISTRATION_REQUEST:
            return {
                ...state,
            };
        case ADVOCATE_REGISTRATION_SUCCESS:
            return {
                ...state,
            };
        case ADVOCATE_REGISTRATION_FAILURE:
            return {
                ...state,
            };
        case UPDATE_ADVOCATE_DETAIL_REQUEST:
            return {
                ...state,
            };
        case UPDATE_ADVOCATE_DETAIL_SUCCESS:
            return {
                ...state,
            };
        case UPDATE_ADVOCATE_DETAIL_FAILURE:
            return {
                ...state,
            };
        case ADVOCATE_LIST_REQUEST:
            return {
                ...state,
            };
        case ADVOCATE_LIST_SUCCESS:
            return {
                ...state,
                advocateList: action.payload
            };
        case ADVOCATE_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case 'TRANSACTIONLIST':
            if (!action.payload) {
                return initialState
            }
            else if (Array.isArray(action.payload)) {
                return {
                    ...state,
                    transactionDetails: action.payload
                }
            }
            else {
                return {
                    ...state,
                    transactionDetails: [...state.transactionDetails, action.payload]
                }
            }
        default:
            return state;
    }
}