import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const COURTMASTER_LIST_REQUEST = 'COURTMASTER_LIST_REQUEST';
export const COURTMASTER_LIST_SUCCESS = 'COURTMASTER_LIST_SUCCESS';
export const COURTMASTER_LIST_FAILURE = 'COURTMASTER_LIST_FAILURE';

export const courtMasterListRequest = () => ({
    type: COURTMASTER_LIST_REQUEST
  });
  export const courtMasterListSuccess = payload => ({
    type: COURTMASTER_LIST_SUCCESS,
    payload
  });
  export const courtMasterListFailure = payload => ({
    type: COURTMASTER_LIST_FAILURE,
    payload
  });

  export const courtMasterListAPI = (payload, successData, errorMSg) => {
    return dispatch => {
      dispatch(courtMasterListRequest());
      const url = `${REACT_APP_API_URL}/get-court-master-list`;
      return request('POST', url, payload)
        .then(res => {
          if (res?.data.status === 200) {
            successData(res);
            dispatch(courtMasterListSuccess(res));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(courtMasterListFailure('Something went wrong'));
          throw err;
        });
    };
  };