import request from "components/Utils/Request";
export const ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_REQUEST = "ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_REQUEST";
export const ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_SUCCESS = "ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_SUCCESS";
export const ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_FAILURE = "ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_FAILURE";
export const ADVOCATE_REGISTRATION_AUTHORIZATION_REQUEST = "ADVOCATE_REGISTRATION_AUTHORIZATION_REQUEST";
export const ADVOCATE_REGISTRATION_AUTHORIZATION_SUCCESS = "ADVOCATE_REGISTRATION_AUTHORIZATION_SUCCESS";
export const ADVOCATE_REGISTRATION_AUTHORIZATION_FAILURE = "ADVOCATE_REGISTRATION_AUTHORIZATION_FAILURE";
const { REACT_APP_API_URL } = process.env;

export const advocateRegistrationAuthorizationListRequest = () => ({
	type: ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_REQUEST
});
export const advocateRegistrationAuthorizationListSuccess = (payload) => ({
	type: ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_SUCCESS,
	payload
});
export const advocateRegistrationAuthorizationListFailure = (payload) => ({
	type: ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_FAILURE,
	payload
})

export const advocateRegistrationAuthorizationRequest = () => ({
	type: ADVOCATE_REGISTRATION_AUTHORIZATION_REQUEST
});
export const advocateRegistrationAuthorizationSuccess = (payload) => ({
	type: ADVOCATE_REGISTRATION_AUTHORIZATION_SUCCESS,
	payload
});
export const advocateRegistrationAuthorizationFailure = (payload) => ({
	type: ADVOCATE_REGISTRATION_AUTHORIZATION_FAILURE,
	payload
})

export const advocateRegistrationAuthorizationListAPI = (payload, successData, errorMSg) => {
	return (dispatch) => {
		dispatch(advocateRegistrationAuthorizationListRequest());
		return request("POST", `${REACT_APP_API_URL + "/advocate-registration-authorization-list"}`, payload)
			.then((res) => {
				if (res?.data.status === 200) {
					successData(res)
					dispatch(advocateRegistrationAuthorizationListSuccess(res));
				} else {
					errorMSg(res)
				}
			}).catch((err) => {
				errorMSg(err?.res);
				dispatch(advocateRegistrationAuthorizationListFailure("Something went wrong"));
				throw err;
			})
	}
}

export const advocateRegistrationAuthorizationAPI = (payload, successData, errorMsg) => {
	return (dispatch) => {
		dispatch(advocateRegistrationAuthorizationRequest());
		return request("POST", `${REACT_APP_API_URL + "/add-advocate-registration-authorization"}`, payload)
			.then((response) => {
				if (response?.data?.status === 200) {
					successData(response);
					dispatch(advocateRegistrationAuthorizationSuccess(response));
				} else {
					errorMsg(response);
				}

			}).catch((error) => {
				errorMsg(error?.response);
				dispatch(advocateRegistrationAuthorizationFailure("Something Went Wrong"));
				throw error;
			});
	};
};