import request from "components/Utils/Request";
export const SECURITY_ROLE_MASTER_REQUEST = "SECURITY_ROLE_MASTER_REQUEST";
export const SECURITY_ROLE_MASTER_SUCCESS = "SECURITY_ROLE_MASTER_SUCCESS";
export const SECURITY_ROLE_MASTER_FAILURE = "SECURITY_ROLE_MASTER_FAILURE";

const { REACT_APP_API_URL } = process.env;

export const securityRoleMasterRequest = () => ({
    type: SECURITY_ROLE_MASTER_REQUEST
});
export const securityRoleMasterSuccess = (payload) => ({
    type: SECURITY_ROLE_MASTER_SUCCESS,
    payload
});
export const securityRoleMasterFailure = (payload) => ({
    type: SECURITY_ROLE_MASTER_FAILURE,
    payload
})

export const securityRoleMasterListAPI = ( successData, errorMSg) => {
    return (dispatch) => {
        dispatch(securityRoleMasterRequest(null));
        const url = `${REACT_APP_API_URL}/security-role-master-list`;
        return request("GET",url)
            .then((res) => {

                if (res?.data?.status === 200) {
                    successData(res)
                    dispatch(securityRoleMasterSuccess(res));
                }
                else {
                    errorMSg(res)
                }
            }).catch((error) => {
                errorMSg(error?.res);
                dispatch(securityRoleMasterFailure("Something went wrong"));
                throw error;
            });
    };
};