import { COURTMASTER_LIST_REQUEST,
    COURTMASTER_LIST_SUCCESS,
    COURTMASTER_LIST_FAILURE
 } from "actions/CourtMaster/courtMasterAction";
 const initialState = {
    courtMasterList: [],
  };

  export default function courtMasterReducer(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case COURTMASTER_LIST_REQUEST:
        return {
          ...state
        };
      case COURTMASTER_LIST_SUCCESS:
        return {
          ...state,
          clientList: action.payload
        };
      case COURTMASTER_LIST_FAILURE:
        return {
          ...state,
          error: action.payload
        };
      default:
        return state;
    }
  }