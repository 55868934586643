

const initialState = {
  clientDetailsError: {
    clientNameErr: {},
    countryErr: {},
    stateErr: {},
    emailErr: {},
    mobileNoErr: {},
    clientTypeErr:{},
    genderErr:{},
    contactPersonErr:{}
  }
};

const clientDetailsErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLIENTDETAILSERROR': 
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          clientDetailsError: action.payload
        };
      }
    default:
      return state;
  }
};

export default clientDetailsErrorReducer;