import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from '../../actions/index';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { countryListAPI } from 'actions/CountryMaster/countryMasterAction';
import { toast } from 'react-toastify';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction';
import {
  checkClientEmailExistsAPI,
  clientDetailsAction
} from 'actions/ClientRegistration/ClientRegistrationAction';

export const ClientDetail = () => {
  const dispatch = useDispatch();

  const resetClientData = () => {
    dispatch(
      clientDetailsAction({
        'clientName': '',
        'encryptedAdvocateCode': '',
        'encryptedClientCode': '',
        'clientAddress1': '',
        'clientAddress2': '',
        'clientAddress3': '',
        'pinCode': '',
        'mobileNo': '',
        'emailId': '',
        'contactPerson': '',
        'countryCode': '',
        'country': '',
        'stateCode': '',
        'state': '',
        'landLineOffice': '',
        'landLineNo': '',
        'clientType': '',
        'occupation': '',
        'sendMail': '',
        'sendSMS': '',
        'sendWhatsapp': ''
      })
    );
  };
  const clientDetailReducer = useSelector(
    state => state.rootReducer.clientRegistrationReducer
  );
  var clientData = clientDetailReducer.clientData;

  if (
    !clientDetailReducer.clientData ||
    Object.keys(clientDetailReducer.clientData).length <= 0
  ) {
    resetClientData();
  }

  const clientDetailsErrorReducer = useSelector(
    state => state.rootReducer.clientDetailsErrorReducer
  );
  const clientError = clientDetailsErrorReducer.clientDetailsError;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [formHasError, setFormError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [existingCientName, setExistingClientName] = useState();

  useEffect(() => {
    getCountries();
  }, [])
  

  useEffect(() => {    
    if (clientData && clientData.stateCode && !$('#txtState').val()) {
      getStates(clientData.countryCode);
    }
  }, [clientData]);

  const getCountries = () => {
    dispatch(
      countryListAPI(
        null,
        res => {
          let countryData = [];
          if (res.data.data.length > 0)
            res.data.data.forEach(country => {
              countryData.push({
                key: country.countryName,
                value: country.countryCode
              });
            });
          setCountryList(countryData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching countries', {
            theme: 'colored',
            autoClose: 10000
          });
          setCountryList([]);
        }
      )
    );
  };

  const getStates = async selectCountryCode => {
    const data = {
      countryCode: selectCountryCode
    };
    dispatch(
      stateListAPI(
        data,
        res => {
          let stateData = [];
          if (res.data && res.data.data.length > 0) {
            res.data.data.forEach(state => {
              stateData.push({
                key: state.stateName,
                value: state.stateCode
              });
            });
            setStateList(stateData);
          } else {
            setStateList([]);
          }
        },
        err => {
          setStateList([]);
        }
      )
    );
  };

  const handleFieldChange = e => {
    if (e.target.name == 'sendSMS') {
      dispatch(
        clientDetailsAction({
          ...clientData,
          sendSMS: e.target.checked
        })
      );
    } else if (e.target.name == 'sendMail') {
      dispatch(
        clientDetailsAction({
          ...clientData,
          sendMail: e.target.checked
        })
      );
    } else if (e.target.name == 'sendWhatsapp') {
      dispatch(
        clientDetailsAction({
          ...clientData,
          sendWhatsapp: e.target.checked
        })
      );
    } else if (e.target.name == 'countryCode') {
      dispatch(
        clientDetailsAction({
          ...clientData,
          countryCode: e.target.value,
          stateCode: null
        })
      );
      setStateList([]);
      e.target.value && getStates(e.target.value);
    } else {
      dispatch(
        clientDetailsAction({
          ...clientData,
          [e.target.name]: e.target.value
        })
      );
    }

    if (clientData.encryptedClientCode) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          clientUpdate: true
        })
      );
    } else {
      dispatch(
        formChangedAction({
          ...formChangedData,
          clientAdd: true
        })
      );
    }
  };

  const checkEmailExists = async () => {
    if (clientData.emailId) {
      const requestData = {
        encryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
        emailId: clientData.emailId,
        encryptedClientCode: localStorage.getItem('EncryptedResponseClientCode')
          ? localStorage.getItem('EncryptedResponseClientCode')
          : '',
        isUpdate: localStorage.getItem('EncryptedResponseClientCode')
          ? true
          : false
      };
      dispatch(
        checkClientEmailExistsAPI(
          requestData,
          response => {
            if (response.data.status == 200) {
              setModalShow(true);
              setExistingClientName(response.data.data.clientName);
            }
          },
          err => {
            console.log(err?.data.message);
            setModalShow(false);
            setExistingClientName('');
          }
        )
      );
    }
  };

  const clearEmail = () => {
    dispatch(
      clientDetailsAction({
        ...clientData,
        emailId: ''
      })
    );
    setModalShow(false);
    setExistingClientName('');
  };

  const closeModal = () => {
    setModalShow(false);
    setExistingClientName('');
  };

  return (
    <>
      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => closeModal()}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>
              Email already associated with another client {existingCientName}
            </h4>
            <h4>Do you want to continue?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => closeModal()}>
              Yes
            </Button>
            <Button variant="danger" onClick={() => clearEmail()}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {clientData && (
        <>
          <Form
            noValidate
            validated={formHasError}
            className="details-form"
            id="addClientDetailsForm"
          >
            <Row>
              <Col className="me-3 ms-3">
                <Row className="mb-3">
                  <Form.Label>
                    Client Name<span className="text-danger">*</span>
                  </Form.Label>
                  <EnlargableTextbox
                    id="txtClientName"
                    name="clientName"
                    maxLength={50}
                    value={clientData.clientName}
                    onChange={handleFieldChange}
                    placeholder="Client Name"
                    required={true}
                  />
                  {Object.keys(clientError.clientNameErr).map(key => {
                    return (
                      <span className="error-message">
                        {clientError.clientNameErr[key]}
                      </span>
                    );
                  })}
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Contact Person<span className="text-danger">*</span>
                  </Form.Label>
                  <EnlargableTextbox
                    id="contactPerson"
                    maxLength={50}
                    value={clientData.contactPerson}
                    name="contactPerson"
                    onChange={handleFieldChange}
                    placeholder="Contact Person"
                  />
                  {Object.keys(clientError.contactPersonErr).map(key => {
                    return (
                      <span className="error-message">
                        {clientError.contactPersonErr[key]}
                      </span>
                    );
                  })}
                </Row>
                <Row className="mb-3">
                  <Form.Label>Client Address</Form.Label>
                  <EnlargableTextbox
                    id="txtClientAddress"
                    name="clientAddress1"
                    maxLength={200}
                    value={clientData.clientAddress1}
                    onChange={handleFieldChange}
                    className="mb-1"
                    placeholder="Address"
                  />
                  <EnlargableTextbox
                    id="txtClientAddress2"
                    name="clientAddress2"
                    maxLength={200}
                    value={clientData.clientAddress2}
                    onChange={handleFieldChange}
                    className="mb-1"
                    placeholder="Address 2"
                  />
                  <EnlargableTextbox
                    id="txtClientAddress3"
                    name="clientAddress3"
                    maxLength={200}
                    value={clientData.clientAddress3}
                    onChange={handleFieldChange}
                    className="mb-1"
                    placeholder="Address 3"
                  />
                </Row>

                <Row className="mb-3">
                  <Form.Label>Pin Code</Form.Label>
                  <EnlargableTextbox
                    id="txtPinCode"
                    name="pinCode"
                    maxLength={10}
                    value={clientData.pinCode}
                    onChange={handleFieldChange}
                    placeholder="Pin Code"
                  />
                </Row>
              </Col>
              <Col className="me-3 ms-3">
                <Row className="mb-3">
                  <Form.Label>
                    Country<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    id="txtCountry"
                    name="countryCode"
                    value={clientData.countryCode}
                    onChange={handleFieldChange}
                    required
                  >
                    <option value="">Select Country</option>
                    {countryList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </Form.Select>
                  {Object.keys(clientError.countryErr).map(key => {
                    return (
                      <span className="error-message">
                        {clientError.countryErr[key]}
                      </span>
                    );
                  })}
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    State<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    id="txtState"
                    name="stateCode"
                    value={clientData.stateCode}
                    onChange={handleFieldChange}
                    required
                  >
                    <option value="">Select State</option>
                    {stateList.map((option, index) => (
                      <option key={index} value={option.value}>
                        {option.key}
                      </option>
                    ))}
                  </Form.Select>
                  {Object.keys(clientError.stateErr).map(key => {
                    return (
                      <span className="error-message">
                        {clientError.stateErr[key]}
                      </span>
                    );
                  })}
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Email ID<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    id="txtEmail"
                    name="emailId"
                    maxLength={100}
                    value={clientData.emailId}
                    onChange={handleFieldChange}
                    placeholder="Email ID"
                    onBlur={() => checkEmailExists()}
                    required
                  />
                  {Object.keys(clientError.emailErr).map(key => {
                    return (
                      <span className="error-message">
                        {clientError.emailErr[key]}
                      </span>
                    );
                  })}
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Mobile No.<span className="text-danger">*</span>
                  </Form.Label>
                  <EnlargableTextbox
                    id="txtMobileNo"
                    name="mobileNo"
                    maxLength={10}
                    value={clientData.mobileNo}
                    onChange={handleFieldChange}
                    placeholder="Mobile No."
                    onKeyPress={e => {
                      const regex = /[0-9]|\./;
                      const key = String.fromCharCode(e.charCode);
                      if (!regex.test(key)) {
                        e.preventDefault();
                      }
                    }}
                    required
                  />
                  {Object.keys(clientError.mobileNoErr).map(key => {
                    return (
                      <span className="error-message">
                        {clientError.mobileNoErr[key]}
                      </span>
                    );
                  })}
                </Row>
                <Row className="mb-3">
                  <Form.Label>Land Line No.</Form.Label>
                  <EnlargableTextbox
                    id="txtlandLineNo"
                    name="landLineNo"
                    maxLength={24}
                    value={clientData.landLineNo}
                    onChange={handleFieldChange}
                    placeholder="Land Line No."
                    onKeyPress={e => {
                      const regex = /[0-9]|\./;
                      const key = String.fromCharCode(e.charCode);
                      if (!regex.test(key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Row>
              </Col>

              <Col className="me-3 ms-3">
                <Row className="mb-3">
                  <Form.Label>Land Line Office</Form.Label>
                  <EnlargableTextbox
                    id="txtlandLineOffice"
                    name="landLineOffice"
                    maxLength={26}
                    value={clientData.landLineOffice}
                    onChange={handleFieldChange}
                    placeholder="Land Line Office"
                  />
                </Row>
                <Row className="mb-3">
                  <Form.Label>Occupation</Form.Label>
                  <EnlargableTextbox
                    id="txtOccupation"
                    name="occupation"
                    maxLength={50}
                    value={clientData.occupation}
                    onChange={handleFieldChange}
                    className="mb-1"
                    placeholder="Occupation"
                  />
                </Row>
                <Row className="mb-3">
                  <Form.Label>
                    Client Type<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    id="txtClientType"
                    name="clientType"
                    maxLength={15}
                    value={clientData.clientType}
                    onChange={handleFieldChange}
                  >
                    <option value="">Select </option>
                    <option value="INDIVIDUAL">INDIVIDUAL</option>
                    <option value="ORGANIZATION">ORGANIZATION</option>
                  </Form.Select>
                  {Object.keys(clientError.clientTypeErr).map(key => {
                    return (
                      <span className="error-message">
                        {clientError.clientTypeErr[key]}
                      </span>
                    );
                  })}
                </Row>
                {clientData.clientType == 'INDIVIDUAL' && (
                  <Row className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      id="txtGender"
                      name="gender"
                      maxLength={15}
                      value={clientData.gender}
                      onChange={handleFieldChange}
                    >
                      <option value="">Select </option>
                      <option value="MALE">MALE</option>
                      <option value="FEMALE">FEMALE</option>
                      <option value="TRANSGENDER">TRANSGENDER</option>
                    </Form.Select>
                    {Object.keys(clientError.genderErr).map(key => {
                      return (
                        <span className="error-message">
                          {clientError.genderErr[key]}
                        </span>
                      );
                    })}
                  </Row>
                )}
                <Row className="" id="contactListChkBoxRow">
                  <Form.Label>Do You Want</Form.Label>
                  <Col xs="">
                    <Form.Check
                      type="checkbox"
                      id="sendSMSChkBox"
                      className="mb-1"
                    >
                      <Form.Check.Input
                        type="checkbox"
                        name="sendSMS"
                        id='checkSendSMS'
                        onChange={handleFieldChange}
                        checked={clientData.sendSMS}
                      />
                      <Form.Check.Label className="mb-0 text-700">
                        Send SMS
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>

                  <Col xs="">
                    <Form.Check
                      type="checkbox"
                      id="sendEMAILChBox"
                      className="mb-1"
                    >
                      <Form.Check.Input
                        type="checkbox"
                          id='checkSendEmail'
                        name="sendMail"
                        onChange={handleFieldChange}
                        checked={clientData.sendMail}
                      />
                      <Form.Check.Label className="mb-0 text-700">
                        Send Email
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>

                  <Col xs="">
                    <Form.Check
                      type="checkbox"
                      id="sendWhatsAppChBox"
                      className="mb-1"
                    >
                      <Form.Check.Input
                        type="checkbox"
                        name="sendWhatsapp"
                        onChange={handleFieldChange}
                        checked={clientData.sendWhatsapp}
                      />
                      <Form.Check.Label className="mb-0 text-700">
                        Send WhatsApp
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default ClientDetail;
