

const initialState = {
  advocateDetailsError: {
    advocateNameErr: {},
    countryErr: {},
    stateErr: {},
    chamberNoErr: {},
    emailErr: {},
    mobileNoErr: {},
    noOfUsersErr:{},
    advocatePicFileErr:{},
    transactionDetailErr:{}
  }
};

const advocateDetailsErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADVOCATEDETAILSERROR':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          advocateDetailsError: action.payload
        };
      }
    default:
      return state;
  }
};

export default advocateDetailsErrorReducer;