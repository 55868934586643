import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { advocateDetailsAction, advocateDetailsErrorAction, formChangedAction } from 'actions';
import { advocateListAPI, advocateRegistrationAPI, advocateUpdateDetailsAPI, transactionDetailsAction } from 'actions/AdvocateRegistration/advocateRegistrationAction';
import { advocateRegistrationAuthorizationAPI, advocateRegistrationAuthorizationListAPI } from 'actions/AdvocateRegistrationAuthorization/advocateRegistrationAuthorizationAction';

const tabArray = ['Advocate List', 'Advocate Details', 'Transaction Details'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'advocateName', Header: 'Advocate Name' },
  { accessor: 'mobileNo', Header: 'Mobile No' },
  { accessor: 'email', Header: 'Email ID' },
  { accessor: 'totalClient', Header: 'Total Client' },
  { accessor: 'totalCase', Header: 'Total No of Case' }
];

export const Advocate = () => {
  const dispatch = useDispatch();
  const [advocateList, setAdvocateList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const advocateDetailsReducer = useSelector((state) => state.rootReducer.advocateDetailsReducer)
  const advocateData = advocateDetailsReducer.advocateDetails;

  const advocateReducer = useSelector((state) => state.rootReducer.advocateRegistrationReducer)
  const transactionDetailData = advocateReducer.transactionDetails;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(value => value === true);

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();
  useEffect(() => {
    getAdvocateList(1);

  }, []);

  const clearAdvocateReducers = () => {
    dispatch(transactionDetailsAction(undefined));
    dispatch(advocateDetailsAction(undefined));
    dispatch(advocateDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
  }

  $('[data-rr-ui-event-key*="Advocate List"]').off('click').on('click', function () {
    let isDiscard = $('#btnDiscard').attr('isDiscard');
    if (isDiscard != 'true' && isFormChanged) {
      setModalShow(true);
      setTimeout(function () {
        $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
      }, 50);
    }
    else {
      $("#btnNew").show();
      $("#btnSave").hide();
      $("#btnCancel").hide();
      $('[data-rr-ui-event-key*="Details"]').attr('disabled', true);
      $('[data-rr-ui-event-key*="Advocate List"]').attr('disabled', false);
      $('#AddAdvocateTransactionDetailsForm').get(0).reset();
      $('#addAdvocateDetailsForm').get(0).reset();
      localStorage.removeItem("EncryptedResponseAdvocateCode");
      $("#btnDiscard").attr("isDiscard", false)
      clearAdvocateReducers();
    }
  })

  $('[data-rr-ui-event-key*="Advocate Details"]').off('click').on('click', function () {
    setActiveTabName("Advocate Details")
    $("#btnNew").hide();
    $("#btnSave").show();
    $("#btnCancel").show();
    if (transactionDetailData.length <= 0 && (localStorage.getItem("EncryptedResponseAdvocateCode") || advocateData.encryptedAdvocateCode)) {
      getTransactionDetailsList();
    }
  })
  $('[data-rr-ui-event-key*="Transaction Details"]').off('click').on('click', function () {
    setActiveTabName("Transaction Details")
    $("#btnNew").hide();
    $("#btnSave").show();
    $("#btnCancel").show();
    if (transactionDetailData.length <= 0 && (localStorage.getItem("EncryptedResponseAdvocateCode") || advocateData.encryptedAdvocateCode)) {
      getTransactionDetailsList();
    }
  })

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Details"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Advocate Details"]').trigger('click');
   $("#TransactionDetailsListCard").hide();
    $('#btnSave').attr('disabled', false);
    clearAdvocateReducers();
  }

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true)
    } else {
      $('[data-rr-ui-event-key*="Advocate List"]').trigger('click');
    }
  }

  const advocateValidation = () => {
    const advocateNameErr = {};
    const emailErr = {};
    const countryErr = {};
    const stateErr = {};
    const chamberNoErr = {};
    const mobileNoErr = {};
    const noOfUsersErr = {};
    const advocatePicFileErr = {};
    const transactionDetailErr = {};

    const maxFileSizeInBytes = 2 * 1024 * 1024;

    let isValid = true;
    let isAdvocateValid = true;

    if (!advocateData.advocateName) {
      advocateNameErr.nameEmpty = "Enter advocate name";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.chamberNo) {
      chamberNoErr.chamberNoEmpty = "Enter chamber no";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.countryCode) {
      countryErr.empty = "Select country";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.stateCode) {
      stateErr.empty = "Select state";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.mobileNo) {
      mobileNoErr.mobileNoErr = "Enter mobile no";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }
    else if (!/^(?!0)[0-9]{10}$/.test(advocateData.mobileNo)) {
      mobileNoErr.phoneInvalid =
        "Invalid mobile number, number should be of 10 digits should not start with 0";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.email) {
      emailErr.empty = "Enter email address";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(advocateData.email))) {
      emailErr.invalid = "Please enter valid email address";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (advocateData.noOfUsers <= 0 || advocateData.noOfUsers === undefined) {
      noOfUsersErr.noOfUsersEmpty = "Enter no. of users";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    } else if (!(/^\d{1,3}$/.test(advocateData.noOfUsers))) {
      noOfUsersErr.noOfUsersInvalid = "Number of users can not be greater than 999";
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }
    if (advocateData.advocatePicFile != null && advocateData.advocatePicFile != "") {
      const allowedExtensions = ["jpg", "jpeg", "png", "svg"];
      const fileExtension = (advocateData.advocatePicFile.name || "").split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        advocatePicFileErr.FileImgInvalid = "Invalid file extension. Please upload a file with extensions jpg, jpeg, png, or svg";
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
      if (advocateData.advocatePicFile.size > maxFileSizeInBytes) {
        advocatePicFileErr.FileImgSize = "File size exceeds the maximum allowed 2 MB";
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
    }

    if (!isAdvocateValid) {
      if (!$('[data-rr-ui-event-key*="Customer Details"]').hasClass('active')) {
        $('[data-rr-ui-event-key*="Customer Details"]').trigger('click');
      }
    }

    if (transactionDetailData.length < 1) {
      transactionDetailErr.transactionEmpty = "At least one transaction detail required";
      setTimeout(() => {
        toast.error(transactionDetailErr.transactionEmpty, {
          theme: 'colored'
        });
      }, 1000);
      isValid = false;

      if (isAdvocateValid &&

        !$('[data-rr-ui-event-key*="Transaction Details"]').hasClass('active')) {
        $('[data-rr-ui-event-key*="Transaction Details"]').trigger('click');
      }
      setFormError(true);
    }
    if (!isValid) {
      var errorObject = {
        advocateNameErr,
        emailErr,
        countryErr,
        stateErr,
        chamberNoErr,
        mobileNoErr,
        noOfUsersErr,
        advocatePicFileErr,
        transactionDetailErr
      }
      dispatch(advocateDetailsErrorAction(errorObject))
    }

    return isValid;
  }

  const addAdvocateDetails = () => {
    if (advocateValidation()) {
      const formData = new FormData();
      formData.append("AdvocateName", advocateData.advocateName.toUpperCase());
      formData.append("AdvocateAddress1", advocateData.AdvocateAddress1 ? advocateData.AdvocateAddress1.toUpperCase() : "")
      formData.append("AdvocateAddress2", advocateData.AdvocateAddress2 ? advocateData.AdvocateAddress2.toUpperCase() : '')
      formData.append("AdvocateAddress3", advocateData.AdvocateAddress3 ? advocateData.AdvocateAddress3.toUpperCase() : "")
      formData.append("CountryCode", advocateData.countryCode)
      formData.append("StateCode", advocateData.stateCode)
      formData.append("EmailId", advocateData.email.toUpperCase());
      formData.append("NoOfUsers", advocateData.noOfUsers);
      formData.append("MobileNo", advocateData.mobileNo)
      formData.append("PracticingSince", advocateData.practiceSince ? advocateData.practiceSince.toUpperCase() : "")
      formData.append("ChamberNo", advocateData.chamberNo)
      formData.append("landlineNo", advocateData.landlineNo ? advocateData.landlineNo : '')
      formData.append("NeedSEO", advocateData.needSEO ? advocateData.needSEO.toUpperCase() : "")
      formData.append("ClientProfile", advocateData.clientProfile ? advocateData.clientProfile.toUpperCase() : "")
      formData.append("ContactPerson", advocateData.contactPerson ? advocateData.contactPerson.toUpperCase() : "");
      formData.append("AdvocatePictureFile", advocateData.advocatePicFile ? advocateData.advocatePicFile : null)
      formData.append("AddUser", localStorage.getItem("LoginUserName").toUpperCase());

      transactionDetailData.forEach((Detail, index) => {
        formData.append(`AdvocateRegistrationAuthorization[${index}].EncryptedRoleId`, Detail.encryptedRoleId);
        formData.append(`AdvocateRegistrationAuthorization[${index}].PaymentMode`, Detail.paymentMode);
        formData.append(`AdvocateRegistrationAuthorization[${index}].ChequeNo`, Detail.chequeNo);
        formData.append(`AdvocateRegistrationAuthorization[${index}].ChequeDate`, Detail.chequeDate);
        formData.append(`AdvocateRegistrationAuthorization[${index}].ChequeBank`, Detail.chequeBank);
        formData.append(`AdvocateRegistrationAuthorization[${index}].GSTPercent`, Detail.gstPercent);
        formData.append(`AdvocateRegistrationAuthorization[${index}].Amount`, Detail.amount);
        formData.append(`AdvocateRegistrationAuthorization[${index}].Startdate`, Detail.startDate);
        formData.append(`AdvocateRegistrationAuthorization[${index}].EndDate`, Detail.endDate);
        formData.append(`AdvocateRegistrationAuthorization[${index}].AddUser`, Detail.addUser.toUpperCase());
      });

      dispatch(
        advocateRegistrationAPI(
          formData,
          (response) => {
            setIsLoading(false)
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            updateCallback(true, response.data.data.encryptedAdvocateCode);
            setModalShow(false);
            localStorage.setItem("EncryptedResponseAdvocateCode", response.data.data.encryptedAdvocateCode)
          },
          (err) => {
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          }
        )
      );
    }
  }
  const updateCallback = (isAddAdvocate = false, encryptedAdvocateCode) => {
    setModalShow(false);
    dispatch(advocateDetailsErrorAction(undefined));
    dispatch(transactionDetailsAction(undefined));
    dispatch(formChangedAction(undefined));

    if (!isAddAdvocate) {
      toast.success("Advocate details updated successfully!", {
        theme: 'colored'
      });
    }
    else {
      dispatch(advocateDetailsAction({
        ...advocateData,
        encryptedAdvocateCode: encryptedAdvocateCode
      }));
    }

    setTimeout(() => {
      getTransactionDetailsList();
    }, 500);

    $('#btnSave').attr('disabled', true)
    getAdvocateList(1);

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  }

  const updateAdvocateDetails = () => {
    if (advocateValidation()) {
      const formData = new FormData();
      formData.append("EncryptedAdvocateCode", advocateData.encryptedAdvocateCode);
      formData.append("AdvocateName", advocateData.advocateName.toUpperCase());
      formData.append("AdvocateAddress1", advocateData.AdvocateAddress1 ? advocateData.AdvocateAddress1.toUpperCase() : "")
      formData.append("AdvocateAddress2", advocateData.AdvocateAddress2 ? advocateData.AdvocateAddress2.toUpperCase() : '')
      formData.append("AdvocateAddress3", advocateData.AdvocateAddress3 ? advocateData.AdvocateAddress3.toUpperCase() : "")
      formData.append("CountryCode", advocateData.countryCode)
      formData.append("StateCode", advocateData.stateCode)
      formData.append("EmailId", advocateData.email.toUpperCase());
      formData.append("MobileNo", advocateData.mobileNo)
      formData.append("NoOfUsers", advocateData.noOfUsers);
      formData.append("PracticingSince", advocateData.practiceSince ? advocateData.practiceSince.toUpperCase() : "")
      formData.append("ChamberNo", advocateData.chamberNo)
      formData.append("landlineNo", advocateData.landlineNo ? advocateData.landlineNo : '')
      formData.append("NeedSEO", advocateData.needSEO != null ? advocateData.needSEO.toUpperCase() : "")
      formData.append("ClientProfile", advocateData.clientProfile ? advocateData.clientProfile.toUpperCase() : "")
      formData.append("ContactPerson", advocateData.contactPerson ? advocateData.contactPerson.toUpperCase() : "");
      formData.append("AdvocatePictureFile", advocateData.advocatePicFile ? advocateData.advocatePicFile : null)
      formData.append("modifyUser", localStorage.getItem("LoginUserName").toUpperCase())

      var hasError = false;
      if (formChangedData.advocateUpdate) {
        dispatch(
          advocateUpdateDetailsAPI(
            formData,
            (res) => {
              setIsLoading(false)
              setModalShow(false);
            },
            (err) => {
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              hasError = true;
            }
          )
        );
      }

      if (!hasError && formChangedData.transactionDetailAdd) {
        var transactionDetailIndex = 1;
        var newTransactions = transactionDetailData.filter(x => !x.encryptedAdvocateRegisterationAuthorizationId);

        for (let i = 0; i < newTransactions.length; i++) {
          const transactionDetail = newTransactions[i];
          setIsLoading(true)
          dispatch(
            advocateRegistrationAuthorizationAPI(
              transactionDetail,
              (res) => {
                setIsLoading(false)
              },
              (err) => {
                toast.error(err?.data.message, {
                  theme: 'colored',
                  autoClose: 10000
                });
                hasError = true;
              }
            )
          );
          if (hasError) break;
        }
        transactionDetailIndex++;
      }
      if (!hasError) {
        updateCallback();
      }
    }
  }


  const getAdvocateList = (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: ""
    }
    dispatch(
      advocateListAPI(
        data,
        (response) => {
          setIsLoading(false)
          setAdvocateList(response.data.data.advocateList)
        },
        (err) => {
          setAdvocateList([]);
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
        }
      )
    );
  }
  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true)
    } else {
      window.location.href = '/dashboard';
    }
  }

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else
      $('[data-rr-ui-event-key*="List"]').trigger('click');

    setModalShow(false);
  }
  const getTransactionDetailsList = async () => {
    const requestParams = {
      EncryptedAdvocateCode: localStorage.getItem("EncryptedResponseAdvocateCode") ? localStorage.getItem("EncryptedResponseAdvocateCode") : advocateData.encryptedAdvocateCode
    }
    dispatch(advocateRegistrationAuthorizationListAPI(requestParams,

      (res) => {
        if ($('#TransactionDetailsTable tbody tr').length > 1) {
          $('#TransactionDetailsTable tbody tr').remove();
        }
        let transactionDetailsData = [];
        transactionDetailsData = res.data.data.length > 0 ? res.data.data : [];
        dispatch(transactionDetailsAction(transactionDetailsData));
        if (res.data && res.data.data.length > 0) {
          $("#TransactionDetailsTable").show();
          $("#TransactionDetailsListCard").show();
        } else {
          $("#TransactionDetailsTable").hide();
          $("#TransactionDetailsListCard").hide();
        }
      },
      (err) => {
        toast.error(err?.data.message, {
          theme: 'colored',
          autoClose: 10000
        });
        $("#TransactionDetailsTable").hide();
      }
    ))
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow &&
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success"
              onClick={!advocateData.encryptedAdvocateCode ? addAdvocateDetails : updateAdvocateDetails}
            >Save</Button>
            <Button variant="danger" id="btnDiscard"
              onClick={() => discardChanges()}
            >Discard</Button>
          </Modal.Footer>
        </Modal>
      }

      <TabPage
        listData={advocateList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="Advocate"
        saveDetails={advocateData.encryptedAdvocateCode ? updateAdvocateDetails : addAdvocateDetails}
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
      />
    </>
  )
};

export default Advocate;