import request from "components/Utils/Request";
export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_FAILURE = "ADD_ROLE_FAILURE";
export const DELETE_SECURITY_USER_ROLE_MASTER_REQUEST = "DELETE_SECURITY_USER_ROLE_MASTER_REQUEST";
export const DELETE_SECURITY_USER_ROLE_MASTER_SUCCESS = "DELETE_SECURITY_USER_ROLE_MASTER_SUCCESS";
export const DELETE_SECURITY_USER_ROLE_MASTER_FAILURE = "DELETE_SECURITY_USER_ROLE_MASTER_FAILURE";
export const ADD_SECURITY_USER_ROLE_MASTER_REQUEST = "ADD_SECURITY_USER_ROLE_MASTER_REQUEST";
export const ADD_SECURITY_USER_ROLE_MASTER_SUCCESS = "ADD_SECURITY_USER_ROLE_MASTER_SUCCESS";
export const ADD_SECURITY_USER_ROLE_MASTER_FAILURE = "ADD_SECURITY_USER_ROLE_MASTER_FAILURE";
const { REACT_APP_API_URL } = process.env;

// add role API
export const addRoleRequest = () => ({
    type: ADD_ROLE_REQUEST
});
export const addRoleSuccess = (payload) => ({
    type: ADD_ROLE_SUCCESS,
    payload,
});
export const addRoleFailure = (payload) => ({
    type: ADD_ROLE_FAILURE,
    payload,
});

export const deleteSecurityRoleMasterRequest = () => ({
    type: DELETE_SECURITY_USER_ROLE_MASTER_REQUEST
});
export const deleteSecurityRoleMasterSuccess = (payload) => ({
    type: DELETE_SECURITY_USER_ROLE_MASTER_SUCCESS,
    payload,
});
export const deleteSecurityRoleMasterFailure = (payload) => ({
    type: DELETE_SECURITY_USER_ROLE_MASTER_FAILURE,
    payload,
});

export const addSecurityRoleMasterRequest = () => ({
    type: ADD_SECURITY_USER_ROLE_MASTER_REQUEST
});
export const addSecurityRoleMasterSuccess = (payload) => ({
    type: ADD_SECURITY_USER_ROLE_MASTER_SUCCESS,
    payload,
});
export const addSecurityRoleMasterFailure = (payload) => ({
    type: ADD_SECURITY_USER_ROLE_MASTER_FAILURE,
    payload,
});

export const addRoleAPI = (payload, successData, errorMsg) => {
    return (dispatch) => {
        const url = `${REACT_APP_API_URL}/add-role`;
        dispatch(addRoleRequest());
        return request("POST", url, payload)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res);
                    dispatch(addRoleSuccess(res));
                } else {
                    errorMsg(res);
                }
            }).catch((error => {
                errorMsg(error?.res);
                dispatch(addRoleFailure("Something went wrong"));
                throw error;
            }
        ));
    }
}

export const deleteSecurityUserRoleMasterAPI = (payload, successData, errorMsg) => {
    return (dispatch) => {
        const url = `${REACT_APP_API_URL}/delete-security-user-role-master`;
        dispatch(deleteSecurityRoleMasterRequest());
        return request("DELETE", url, payload)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res);
                    dispatch(deleteSecurityRoleMasterSuccess(res));
                } else {
                    errorMsg(res);
                }
            }).catch((error => {
                errorMsg(error?.res);
                dispatch(deleteSecurityRoleMasterFailure("Something went wrong"));
                throw error;
            }
        ));
    }
}

export const addSecurityUserRoleMasterAPI = (payload, successData, errorMsg) => {
    return (dispatch) => {
        const url = `${REACT_APP_API_URL}/add-security-user-role-master`;
        dispatch(addSecurityRoleMasterRequest());
        return request("POST", url, payload)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res);
                    dispatch(addSecurityRoleMasterSuccess(res));
                } else {
                    errorMsg(res);
                }
            }).catch((error => {
                errorMsg(error?.res);
                dispatch(addSecurityRoleMasterFailure("Something went wrong"));
                throw error;
            }
        ));
    }
}