import { combineReducers } from 'redux';
import treeViewReducer from './treeViewReducer';
import shortcutKeyReducer from './shortcutKeyReducer';
import securityUserAssignmentReducer from './SecurityUserAssignment/SecurityUserAssignmentReducer';
import SecurityMenuMenuTreeReducer from './SecurityMenuTree/SecurityMenuTreeReducer';
import advocateDetailsErrorReducer from './AdvocateRegistration/advocateDetailsErrorReducer';
import advocateDataReducer from './AdvocateRegistration/advocateDataReducer';
import advocateDetailsReducer from './AdvocateRegistration/advocateDetailsReducer';
import formChangedReducer from './formChangedReducer';
import tabInfoReducer from './tabinfoReducer';
import userDetailsErrorReducer from './SecurityUserAssignment/userDetailsErrorReducer';
import advocateRegistrationReducer from './AdvocateRegistration/advocateRegistrationReducer';
import selectedProductsReducer from './selectedProductsReducer';
import advocateRegistrationAuthorizationReducer from './AdvocateRegistrationAuthorizationList/advocateRegistrationAuthorizationListReducer';
import clientRegistrationReducer from './ClientRegistration/clientRegistrationReducer';
import clientDetailsErrorReducer from './ClientRegistration/clientDetailsErrorReducer';
import advocateClientCaseReducer from './AdvocateClientCase/AdvocateClientCaseReducer';
import caseTypeMasterReducer from './CaseTypeMaster/caseTypeMasterReducer';
import advocateClientCaseDetailsErrorReducer from './AdvocateClientCase/advocateClientCaseDetailsErrorReducer.js';
import courtMasterReducer from './CourtMaster/courtMasterReducer';


const rootReducer = combineReducers({
  treeViewReducer,
  shortcutKeyReducer,
  securityUserAssignment: securityUserAssignmentReducer,
  securityMenuTree: SecurityMenuMenuTreeReducer,
  advocateDetailsErrorReducer,
  advocateDataReducer,
  advocateDetailsReducer,
  advocateRegistrationReducer,
  formChangedReducer,
  tabInfoReducer,
  userDetailsErrorReducer,
  selectedProductsReducer,
  advocateRegistrationAuthorizationReducer,
  clientRegistrationReducer,
  clientDetailsErrorReducer,
  advocateClientCaseReducer,
advocateClientCaseDetailsErrorReducer,
  caseTypeMasterReducer,
  courtMasterReducer
});

export default rootReducer;