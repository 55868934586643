import {
    ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_REQUEST,
    ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_SUCCESS,
    ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_FAILURE,
    ADVOCATE_REGISTRATION_AUTHORIZATION_REQUEST,
    ADVOCATE_REGISTRATION_AUTHORIZATION_SUCCESS,
    ADVOCATE_REGISTRATION_AUTHORIZATION_FAILURE
} from "actions/AdvocateRegistrationAuthorization/advocateRegistrationAuthorizationAction";
const initialState = {
    transactionDetails: [],
};

export default function advocateRegistrationAuthorizationReducer(state = initialState, action) {
    switch (action.type) {
        case ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_REQUEST:
            return {
                ...state,
            };
        case ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_SUCCESS:
            return {
                ...state,
                transactionDetails: action.payload
            };
        case ADVOCATE_REGISTRATION_AUTHORIZATION_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case ADVOCATE_REGISTRATION_AUTHORIZATION_REQUEST:
            return {
                ...state,
            };

        case ADVOCATE_REGISTRATION_AUTHORIZATION_SUCCESS:
            return {
                ...state,
                transactionDetails: action.payload
            };
        case ADVOCATE_REGISTRATION_AUTHORIZATION_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        default:
            return state;
    }
}