import React, { useContext, useEffect } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from 'context/Context';

const renderTooltip = props => (
  <Tooltip id="button-tooltip" {...props}>
    Toggle Navigation
  </Tooltip>
);

const ToggleButton = () => {
  const {
    config: { isNavbarVerticalCollapsed, isFluid, isRTL },
    setConfig
  } = useContext(AppContext);

  const handleClick = () => {

    if(!isNavbarVerticalCollapsed)
      $('#topLogo').hide();
    else 
    $('#topLogo').show();

    document
      .getElementsByTagName('html')[0]
      .classList.toggle('navbar-vertical-collapsed');
    setConfig('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed);
  };
  // useEffect(() => {
  //   // Ensure the sidebar is open by default when the component mounts
  //   if (isNavbarVerticalCollapsed === false) {
  //     document
  //       .getElementsByTagName('html')[0]
  //       .classList.remove('navbar-vertical-collapsed');
  //     $('#topLogo').show();
  //   } else {
  //     document
  //       .getElementsByTagName('html')[0]
  //       .classList.add('navbar-vertical-collapsed');
  //     $('#topLogo').hide();
  //   }
  // }, []); 
  return (
    <OverlayTrigger
      placement={
        isFluid ? (isRTL ? 'bottom' : 'right') : isRTL ? 'bottom' : 'left'
      }
      overlay={renderTooltip}
    >
      <div className="toggle-icon-wrapper">
        <Button
          variant="link"
          className="navbar-toggler-humburger-icon navbar-vertical-toggle"
          id="toggleNavigationTooltip"
          onClick={handleClick}
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </Button>
      </div>
    </OverlayTrigger>
  );
};

export default ToggleButton;
