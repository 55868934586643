import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Button, InputGroup} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { advocateDataAction, advocateDetailsAction, formChangedAction } from '../../actions/index';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { countryListAPI } from 'actions/CountryMaster/countryMasterAction';
import { toast } from 'react-toastify';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction';

export const AdvocateDetail = () => {
  const dispatch = useDispatch();
  const resetAdvocateData = () => {
    dispatch(advocateDataAction({
      "advocateName": "",
      "encryptedAdvocateCode": "",
      "advocateAddress1": "",
      "advocateAddress2": "",
      "advocateAddress3": "",
      "chamberNo": "",
      "mobileNo": "",
      "email": "",
      "contactPerson": "",
      "countryCode": "",
      "country": "",
      "stateCode": "",
      "state": "",
      "practiceSince": "",
      "landlineNo": "",
      "advocatePicFile": "",
      "advocatePicFileURL": "",
      "needSEO": "",
      "clientProfile": "",
      "noOfUsers": 1,
    }));
  }

  useEffect(() => {
    getCountries();
  }, [])
  

  const advocateDetailsReducer = useSelector((state) => state.rootReducer.advocateDetailsReducer)
  var advocateData = advocateDetailsReducer.advocateDetails;

  if (!advocateDetailsReducer.advocateDetails ||
    advocateDetailsReducer.advocateDetails.length <= 0) {
    resetAdvocateData();
  }
  const advocateDetailsErrorReducer = useSelector((state) => state.rootReducer.advocateDetailsErrorReducer)
  const advocateError = advocateDetailsErrorReducer.advocateDetailsError;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [formHasError, setFormError] = useState(false);

  useEffect(() => {    
    if (advocateData && advocateData.stateCode &&
      !$('#txtState').val()) {
      getStates(advocateData.countryCode);
    }

  }, [advocateData]);


  const getCountries = () => {
    dispatch(countryListAPI(
      null,
      (res) => {
        let countryData = [];
        if (res.data.data.length > 0)
          res.data.data.forEach(country => {
            countryData.push({
              key: country.countryName,
              value: country.countryCode
            });
          });
        setCountryList(countryData);
      },
      (err) => {
        toast.error(err?.data?.message || "Error fetching countries", {
          theme: 'colored',
          autoClose: 10000,
        });
        setCountryList([]);
      }
    ));
  };

  const getStates = async (selectCountryCode) => {
    const data = {
      countryCode: selectCountryCode
    }
    dispatch(stateListAPI(
      data,
      (res) => {

        let stateData = [];
        if (res.data && res.data.data.length > 0) {
          res.data.data.forEach(state => {
            stateData.push({
              key: state.stateName,
              value: state.stateCode
            });
          });
          setStateList(stateData);
        } else {
          setStateList([]);
        }
      },
      (err) => {
        setStateList([]);
      }
    ));
  };


  const handleFieldChange = e => {
    dispatch(advocateDetailsAction({
      ...advocateData,
      [e.target.name]: e.target.value
    }));

    if (e.target.name == "countryCode") {
      if (e.target.value == '')
        setStateList([]);
      else
        getStates(e.target.value);
    }
    if (e.target.name == 'advocatePicFile') {
      if (e.target.files[0]) {
        dispatch(advocateDetailsAction({
          ...advocateData,
          advocatePicFile: e.target.files[0],
          advocatePicFileURL: URL.createObjectURL(e.target.files[0])
        }));
      }
      else {
        dispatch(advocateDetailsAction({
          ...advocateData,
          advocatePicFile: null,
          advocatePicFileURL: ""
        }));
      }
    }
    if (advocateData.encryptedAdvocateCode) {
      dispatch(formChangedAction({
        ...formChangedData,
        advocateUpdate: true
      }))
    } else {
      dispatch(formChangedAction({
        ...formChangedData,
        advocateAdd: true
      }))
    }
  };

  const removeUploadFile = () => {
  
    $('#advocatePicFile').val(null);
    dispatch(advocateDetailsAction({
      ...advocateData,
      advocatePicFile: "",
      advocatePicFileURL: "",
    }))
    dispatch(formChangedAction({
      ...formChangedData,
      advocateUpdate: true
    }))
    $('#btnSave').attr('disabled', false);
  }
  return (
    <>
      {advocateData &&
        <Form noValidate validated={formHasError} className="details-form"
          onSubmit={e => { handleSubmit(e) }}
          id='addAdvocateDetailsForm'>
          <Row>
            <Col className="me-3 ms-3">
              <Row className="mb-3">
                <Form.Label>Advocate Name<span className="text-danger">*</span></Form.Label>
                <EnlargableTextbox id="txtAdvocateName" name="advocateName" maxLength={60}
                  value={advocateData.advocateName} onChange={handleFieldChange}
                  placeholder="Advocate Name" required={true} />
                {Object.keys(advocateError.advocateNameErr).map((key) => {
                  return <span className="error-message">{advocateError.advocateNameErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Chamber No.<span className="text-danger">*</span></Form.Label>
                <EnlargableTextbox id="txtChamberNo" name="chamberNo" maxLength={50}
                  value={advocateData.chamberNo} onChange={handleFieldChange}
                  placeholder="Chamber No." />
                {Object.keys(advocateError.chamberNoErr).map((key) => {
                  return <span className="error-message">{advocateError.chamberNoErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Practice Since</Form.Label>
                <EnlargableTextbox id="txtPracticeSince" name="practiceSince" maxLength={4}
                  value={advocateData.practiceSince} onChange={handleFieldChange}
                  onKeyPress={(e) => {
                    const regex = /[0-9]|\./;
                    const key = String.fromCharCode(e.charCode);
                    if (!regex.test(key)) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Practice Since" />
              </Row>

              <Row className="mb-3">
                <Form.Label>Contact Person</Form.Label>
                <EnlargableTextbox id="contactPerson" maxLength={50}
                  value={advocateData.contactPerson} name="contactPerson" onChange={handleFieldChange}
                  placeholder="Contact Person"

                />
              </Row>
              <Row className="mb-3">
                <Form.Label>Need SEO</Form.Label>
                <Form.Select id="txtNeedSEO" name="needSEO"
                  value={advocateData.needSEO} onChange={handleFieldChange}
                >
                  <option value=''>Select </option>
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </Form.Select>
              </Row>
            </Col>
            <Col className="me-3 ms-3">
              <Row className="mb-3">
                <Form.Label>Country<span className="text-danger">*</span></Form.Label>
                <Form.Select id="txtCountry" name="countryCode"
                  value={advocateData.countryCode} onChange={handleFieldChange}
                  required>
                  <option value=''>Select country</option>
                  {countryList.map((option, index) => (
                    <option key={index} value={option.value}>{option.key}</option>
                  ))}
                </Form.Select>
                {Object.keys(advocateError.countryErr).map((key) => {
                  return <span className="error-message">{advocateError.countryErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>State<span className="text-danger">*</span></Form.Label>
                <Form.Select id="txtState" name="stateCode"
                  value={advocateData.stateCode} onChange={handleFieldChange}
                  required>
                  <option value=''>Select state</option>
                  {stateList.map((option, index) => (
                    <option key={index} value={option.value}>{option.key}</option>
                  ))}
                </Form.Select>
                {Object.keys(advocateError.stateErr).map((key) => {
                  return <span className="error-message">{advocateError.stateErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Email ID<span className="text-danger">*</span></Form.Label>
                <EnlargableTextbox id="txtEmail" name="email" maxLength={100}
                  value={advocateData.email} onChange={handleFieldChange}
                  placeholder="Email ID" required />
                {Object.keys(advocateError.emailErr).map((key) => {
                  return <span className="error-message">{advocateError.emailErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Mobile No.<span className="text-danger">*</span></Form.Label>
                <EnlargableTextbox id="txtMobileNo" name="mobileNo" maxLength={10}
                  value={advocateData.mobileNo} onChange={handleFieldChange}
                  placeholder="Mobile No."
                  onKeyPress={(e) => {
                    const regex = /[0-9]|\./;
                    const key = String.fromCharCode(e.charCode);
                    if (!regex.test(key)) {
                      e.preventDefault();
                    }
                  }} required />
                {Object.keys(advocateError.mobileNoErr).map((key) => {
                  return <span className="error-message">{advocateError.mobileNoErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Landline No.</Form.Label>
                <EnlargableTextbox id="txtlandlineNo" name="landlineNo" maxLength={40}
                  value={advocateData.landlineNo} onChange={handleFieldChange}
                  placeholder="LandLine No." onKeyPress={(e) => {
                    const regex = /[0-9]|\./;
                    const key = String.fromCharCode(e.charCode);
                    if (!regex.test(key)) {
                      e.preventDefault();
                    }
                  }} />
              </Row>
            </Col>

            <Col className="me-3 ms-3">
              <Row className="mb-3">
                <Form.Label>Advocate Address</Form.Label>
                <EnlargableTextbox id="txtAdvocateAddress" name="advocateAddress1" maxLength={200}
                  value={advocateData.advocateAddress1} onChange={handleFieldChange}
                  className="mb-1" placeholder="Address" />
                <EnlargableTextbox id="txtAdvocateAddress2" name="advocateAddress2" maxLength={200}
                  value={advocateData.advocateAddress2} onChange={handleFieldChange}
                  className="mb-1" placeholder="Address 2" />
                <EnlargableTextbox id="txtAdvocateAddress3" name="advocateAddress3" maxLength={200}
                  value={advocateData.advocateAddress3} onChange={handleFieldChange}
                  className="mb-1" placeholder="Address 3" />
              </Row>

              <Row className="mb-3">
                <Form.Label>Client Profile</Form.Label>
                <EnlargableTextbox id="txtClientProfile" name="clientProfile" maxLength={500}
                  value={advocateData.clientProfile} onChange={handleFieldChange}
                  className="mb-1" placeholder="Client Profile" />
              </Row>
              <Row className="mb-3">
                <Form.Label>No. of Users<span className="text-danger">*</span></Form.Label>
                <EnlargableTextbox id="numNoOfUsers" maxLength={4} value={advocateData.noOfUsers} name="noOfUsers" onChange={handleFieldChange} placeholder="No. of Users" required
                  onKeyPress={(e) => {
                    const regex = /[0-9]|\./;
                    const key = String.fromCharCode(e.charCode);
                    if (!regex.test(key)) {
                      e.preventDefault();
                    }
                  }} />
                {Object.keys(advocateError.noOfUsersErr).map((key) => {
                  return <span className="error-message">{advocateError.noOfUsersErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Form.Label>Advocate Profile</Form.Label>
                {
                  (advocateData && advocateData.advocatePicFileURL ) ?
                    (
                      <>
                        <img src={advocateData.advocatePicFileURL} id='advocatePicUploadFile' className='img-thumbnail shadow-sm logo-photo p3px' />
                        <InputGroup className="mb-1 no-padding mt-1">
                          <Button onClick={() => { document.getElementById('advocatePicFile').click(); }}>Change</Button>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={'trash'}
                           onClick={() => { removeUploadFile() }}
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </>
                    )
                    :
                    <Button onClick={() => { document.getElementById('advocatePicFile').click(); }}>Upload</Button>
                }
                {Object.keys(advocateError.advocatePicFileErr).map((key) => {
                  return <span className="error-message">{advocateError.advocatePicFileErr[key]}</span>
                })}
                <Form.Control type="file" id='advocatePicFile' name='advocatePicFile' onChange={handleFieldChange} hidden />
              </Row>
            </Col>
          </Row>
        </Form>
      }
    </>
  )
}

export default AdvocateDetail;