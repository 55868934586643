import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const CLIENT_REGISTRATION_REQUEST = 'CLIENT_REGISTRATION_REQUEST';
export const CLIENT_REGISTRATION_SUCCESS = 'CLIENT_REGISTRATION_SUCCESS';
export const CLIENT_REGISTRATION_FAILURE = 'CLIENT_REGISTRATION_FAILURE';
export const UPDATE_CLIENT_DETAIL_REQUEST = 'UPDATE_CLIENT_DETAIL_REQUEST';
export const UPDATE_CLIENT_DETAIL_SUCCESS = 'UPDATE_CLIENT_DETAIL_SUCCESS';
export const UPDATE_CLIENT_DETAIL_FAILURE = 'UPDATE_CLIENT_DETAIL_FAILURE';
export const CLIENT_LIST_REQUEST = 'CLIENT_LIST_REQUEST';
export const CLIENT_LIST_SUCCESS = 'CLIENT_LIST_SUCCESS';
export const CLIENT_LIST_FAILURE = 'CLIENT_LIST_FAILURE';
export const CLIENT_DETAIL_VIEW_REQUEST = 'CLIENT_DETAIL_VIEW_REQUEST';
export const CLIENT_DETAIL_VIEW_SUCCESS = 'CLIENT_DETAIL_VIEW_SUCCESS';
export const CLIENT_DETAIL_VIEW_FAILURE = 'CLIENT_DETAIL_VIEW_FAILURE';
export const CHECK_CLIENT_EMAIL_EXISTS_REQUEST = 'CHECK_CLIENT_EMAIL_EXISTS_REQUEST';
export const CHECK_CLIENT_EMAIL_EXISTS_SUCCESS = 'CHECK_CLIENT_EMAIL_EXISTS_SUCCESS';
export const CHECK_CLIENT_EMAIL_EXISTS_FAILURE = 'CHECK_CLIENT_EMAIL_EXISTS_FAILURE';

export const updateClientDetailsRequest = () => ({
  type: UPDATE_CLIENT_DETAIL_REQUEST
});
export const updateClientDetailsSuccess = payload => ({
  type: UPDATE_CLIENT_DETAIL_SUCCESS,
  payload
});
export const updateClientDetailsFailure = payload => ({
  type: UPDATE_CLIENT_DETAIL_FAILURE,
  payload
});

export const clientRegistrationRequest = () => ({
  type: CLIENT_REGISTRATION_REQUEST
});
export const clientRegistrationSuccess = payload => ({
  type: CLIENT_REGISTRATION_SUCCESS,
  payload
});
export const clientRegistrationFailure = payload => ({
  type: CLIENT_REGISTRATION_FAILURE,
  payload
});

export const clientDetailRequest = () => ({
  type: CLIENT_DETAIL_VIEW_REQUEST
});
export const clientDetailSuccess = payload => ({
  type: CLIENT_DETAIL_VIEW_SUCCESS,
  payload
});
export const clientDetailFailure = payload => ({
  type: CLIENT_DETAIL_VIEW_FAILURE,
  payload
});

// CLIENT-list
export const clientListRequest = () => ({
  type: CLIENT_LIST_REQUEST
});
export const clientListSuccess = payload => ({
  type: CLIENT_LIST_SUCCESS,
  payload
});
export const clientListFailure = payload => ({
  type: CLIENT_LIST_FAILURE,
  payload
});

export const clientDataAction = payload => {
  return {
    type: 'CLIENTDATA',
    payload
  };
};

export const clientDetailsAction = payload => {
  return {
    type: 'CLIENTDETAILS',
    payload
  };
};

export const clientDetailsErrorAction = payload => {
  return {
    type: 'CLIENTDETAILSERROR',
    payload
  };
};

export const checkClientEmailExistsRequest = () => ({
  type: CHECK_CLIENT_EMAIL_EXISTS_REQUEST
});
export const checkClientEmailExistsSuccess = payload => ({
  type: CHECK_CLIENT_EMAIL_EXISTS_SUCCESS,
  payload
});
export const checkClientEmailExistsFailure = payload => ({
  type: CHECK_CLIENT_EMAIL_EXISTS_FAILURE,
  payload
});

export const clientRegistrationAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(clientRegistrationRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/client-registration'}`,
      payload
    )
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(clientRegistrationSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(clientRegistrationFailure('Something went wrong'));
        throw error;
      });
  };
};

export const clientUpdateDetailsAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(updateClientDetailsRequest());
    return request('POST', `${REACT_APP_API_URL + '/update-client'}`, payload)
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(updateClientDetailsSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(updateClientDetailsFailure('Something went wrong'));
        throw error;
      });
  };
};

export const clientDetailAPI = (payload, successData, errorMSg) => {
  return dispatch => {
    dispatch(clientDetailRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/get-client-detail'}`,
      payload
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(clientDetailSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(clientDetailFailure('Something went wrong'));
        throw err;
      });
  };
};

export const clientListAPI = (payload, successData, errorMSg) => {
  return dispatch => {
    dispatch(clientListRequest());
    const url = `${REACT_APP_API_URL}/client-list`;
    return request('POST', url, payload)
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(clientListSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(clientListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const checkClientEmailExistsAPI = (payload, successData, errorMSg) => {
  return dispatch => {
    dispatch(checkClientEmailExistsRequest());
    const url = `${REACT_APP_API_URL}/check-client-email-exists`;
    return request('POST', url, payload)
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(checkClientEmailExistsSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(checkClientEmailExistsFailure('Something went wrong'));
        throw err;
      });
  };
};
