import request from "components/Utils/Request";
export const MENU_HEADER_TREE_REQUEST = "MENU_HEADER_TREE_REQUEST";
export const MENU_HEADER_TREE_SUCCESS = "MENU_HEADER_TREE_SUCCESS";
export const MENU_HEADER_TREE_FAILURE = "MENU_HEADER_TREE_FAILURE";
export const MENU_TREE_REQUEST = "MENU_TREE_REQUEST";
export const MENU_TREE_SUCCESS = "MENU_TREE_SUCCESS";
export const MENU_TREE_FAILURE = "MENU_TREE_FAILURE";
export const ADVOCATE_USER_MENU_TREE_REQUEST = "ADVOCATE_USER_MENU_TREE_REQUEST";
export const ADVOCATE_USER_MENU_TREE_SUCCESS = "ADVOCATE_USER_MENU_TREE_SUCCESS";
export const ADVOCATE_USER_MENU_TREE_FAILURE = "ADVOCATE_USER_MENU_TREE_FAILURE"; 

const { REACT_APP_API_URL } = process.env;

export const menuHeaderTreeRequest = () => ({
    type: MENU_HEADER_TREE_REQUEST
});
export const menuHeaderTreeSuccess = (payload) => ({
    type: MENU_HEADER_TREE_SUCCESS,
    payload
});
export const menuHeaderTreeFalure = (payload) => ({
    type: MENU_HEADER_TREE_FAILURE,
    payload
})
export const menuTreeRequest = () => ({
    type: MENU_TREE_REQUEST
});
export const menuTreeSuccess = (payload) => ({
    type: MENU_TREE_SUCCESS,
    payload
});
export const menuTreeFailure = (payload) => ({
    type: MENU_TREE_FAILURE,
    payload
})

export const advocateUserMenuTreeRequest = () => ({
    type: ADVOCATE_USER_MENU_TREE_REQUEST
});
export const advocateUserMenuTreeSuccess = (payload) => ({
    type: ADVOCATE_USER_MENU_TREE_SUCCESS,
    payload
});
export const advocateUserMenuTreeFailure = (payload) => ({
    type: ADVOCATE_USER_MENU_TREE_FAILURE,
    payload
})

export const menuHeaderTreeAPI = (payload, successData, errorMSg) => {
    return (dispatch) => {
        dispatch(menuHeaderTreeRequest());
        const url = `${REACT_APP_API_URL}/get-security-menu-tree-header-list/${payload}`;
        return request("GET",url)
            .then((res) => {

                if (res?.data?.status === 200) {
                    successData(res)
                    dispatch(menuHeaderTreeSuccess(res));
                }
                else {
                    errorMSg(res)
                }
            }).catch((error) => {
                errorMSg(error?.res);
                dispatch(menuHeaderTreeFalure("Something went wrong"));
                throw error;
            });
    };
};

export const menuTreeListAPI = (payload,successData,errorMSg) =>{  
    return (dispatch) =>{
        dispatch(menuTreeRequest());
        const url = `${REACT_APP_API_URL}/get-security-menu-tree-menu-list`;
        return request("POST",url,payload)
        .then((res)=>{
            if(res?.data.status === 200){
                successData(res)
                dispatch(menuHeaderTreeSuccess(res));
            }else{
                errorMSg(res)
            }
        }).catch((err) =>{
            errorMSg(err?.res);
            dispatch(menuTreeFailure("Something went wrong"));
            throw err;
        })
    }
}

export const advocateUserMenuTreeListAPI = (payload,successData,errorMSg) =>{  
    return (dispatch) =>{
        dispatch(advocateUserMenuTreeRequest());
        const url = `${REACT_APP_API_URL}/get-advocate-user-menu-tree`;
        return request("POST",url,payload)
        .then((res)=>{
            if(res?.data.status === 200){
                successData(res)
                dispatch(advocateUserMenuTreeSuccess(res));
            }else{
                errorMSg(res)
            }
        }).catch((err) =>{
            errorMSg(err?.res);
            dispatch(advocateUserMenuTreeFailure("Something went wrong"));
            throw err;
        })
    }
}