import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAILURE
} from '../../actions/SecurityUserAssignment/SecurityUserAssignmentAction';

const initialState = {
  loginDetail: null,
  forgotPassword: null,
  userList: [],
  userDetails: {},
  userData: {}
};

export default function SecurityUserAssignmentReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginDetail: action.payload
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: action.payload
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case USER_LIST_REQUEST:
      return {
        ...state
      };
    case USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload
      };
    case USER_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case 'USERDETAILS':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          userDetails: action.payload
        };
      }
    case 'USERDATA':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          userData: action.payload
        };
      }

    default:
      return state;
  }
}
