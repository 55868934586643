import request from "components/Utils/Request";
export const STATE_MASTER_REQUEST = "STATE_MASTER_REQUEST";
export const STATE_MASTER_SUCCESS = "STATE_MASTER_SUCCESS";
export const STATE_MASTER_FAILURE = "STATE_MASTER_FAILURE";

const { REACT_APP_API_URL } = process.env;

export const stateMasterRequest = () => ({
    type: STATE_MASTER_REQUEST
});
export const stateMasterSuccess = (payload) => ({
    type: STATE_MASTER_SUCCESS,
    payload
});
export const stateMasterFailure = (payload) => ({
    type: STATE_MASTER_FAILURE,
    payload
})

export const stateListAPI = (payload, successData, errorMSg) => {
    return (dispatch) => {
        dispatch(stateMasterRequest());
        const url = `${REACT_APP_API_URL}/state-list`;
        return request("POST", url, payload)
            .then((res) => {
                if (res?.data.status === 200) {
                    successData(res)
                    dispatch(stateMasterSuccess(res));
                } else {
                    errorMSg(res)
                }
            }).catch((err) => {
                errorMSg(err?.res);
                dispatch(stateMasterFailure("Something went wrong"));
                throw err;
            })
    }
}