import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

const App = () => {

  return (
    <Router >
      <Layout />
    </Router>
  );
};

export default App;
