import {
  CLIENT_REGISTRATION_REQUEST,
  CLIENT_REGISTRATION_SUCCESS,
  CLIENT_REGISTRATION_FAILURE,
  UPDATE_CLIENT_DETAIL_REQUEST,
  UPDATE_CLIENT_DETAIL_SUCCESS,
  UPDATE_CLIENT_DETAIL_FAILURE,
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAILURE,
  CHECK_CLIENT_EMAIL_EXISTS_REQUEST,
  CHECK_CLIENT_EMAIL_EXISTS_SUCCESS,
  CHECK_CLIENT_EMAIL_EXISTS_FAILURE
} from 'actions/ClientRegistration/ClientRegistrationAction';
const initialState = {
  clientList: [],
  clientDetails: {},
  clientData: {},
  clientEmailExists: {}
};

export default function clientRegistrationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return {
        ...state
      };
    case CLIENT_LIST_SUCCESS:
      return {
        ...state,
        clientList: action.payload
      };
    case CLIENT_LIST_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case CLIENT_REGISTRATION_REQUEST:
      return {
        ...state
      };
    case CLIENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        clientDetails: action.payload
      };
    case CLIENT_REGISTRATION_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case UPDATE_CLIENT_DETAIL_REQUEST:
      return {
        ...state
      };
    case UPDATE_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        clientDetails: action.payload
      };
    case UPDATE_CLIENT_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case 'CLIENTDETAILS':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          clientData: action.payload
        };
      }
    case 'CLIENTDATA':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          clientData: action.payload
        };
      }
    case CHECK_CLIENT_EMAIL_EXISTS_REQUEST:
      return {
        ...state
      };
    case CHECK_CLIENT_EMAIL_EXISTS_SUCCESS:
      return {
        ...state,
        clientEmailExists: action.payload
      };
    case CHECK_CLIENT_EMAIL_EXISTS_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
