import request from "components/Utils/Request";

export const COUNTRY_MASTER_REQUEST = "COUNTRY_MASTER_REQUEST";
export const COUNTRY_MASTER_SUCCESS = "COUNTRY_MASTER_SUCCESS";
export const COUNTRY_MASTER_FAILURE = "COUNTRY_MASTER_FAILURE";

const { REACT_APP_API_URL } = process.env;

export const countryMasterRequest = () => ({
  type: COUNTRY_MASTER_REQUEST,
});

export const countryMasterSuccess = (payload) => ({
  type: COUNTRY_MASTER_SUCCESS,
  payload,
});

export const countryMasterFailure = (payload) => ({
  type: COUNTRY_MASTER_FAILURE,
  payload,
});

export const countryListAPI = (payload, successData, errorMSg) => {
  return (dispatch) => {
    dispatch(countryMasterRequest());
    const url = `${REACT_APP_API_URL}/country-list`;
    return request("GET", url)
      .then((res) => {
        if (res?.data?.status === 200) {
          successData(res);
          dispatch(countryMasterSuccess(res.data.data));
        } else {
          errorMSg(res);
          dispatch(countryMasterFailure(res));
        }
      })
      .catch((error) => {
        errorMSg(error?.response);
        dispatch(countryMasterFailure("Something went wrong"));
        throw error;
      });
  };
};