export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      icon: 'chart-pie',
      to: '/dashboard',
      active: true
    },
  ]
};

export default [dashboardRoutes];