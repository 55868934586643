import { CASE_TYPE_MASTER_LIST_REQUEST,
    CASE_TYPE_MASTER_LIST_SUCCESS,
    CASE_TYPE_MASTER_LIST_FAILURE
 } from "actions/CaseTypeMaster/caseTypeMasterAction";

 const initialState = {
    caseTypeList: [],
  };
  
  export default function caseTypeMasterReducer(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case CASE_TYPE_MASTER_LIST_REQUEST:
        return {
          ...state
        };
      case CASE_TYPE_MASTER_LIST_SUCCESS:
        return {
          ...state,
          clientList: action.payload
        };
      case CASE_TYPE_MASTER_LIST_FAILURE:
        return {
          ...state,
          error: action.payload
        };
      default:
        return state;
    }
  }