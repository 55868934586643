import PropTypes from 'prop-types';
import { Badge, Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../IconButton';
import $ from 'jquery';
import { advocateDetailsAction } from 'actions';
import { userDetailsAction } from 'actions/SecurityUserAssignment/SecurityUserAssignmentAction';
import { clientDetailsAction } from 'actions/ClientRegistration/ClientRegistrationAction';
import {
  advocateClientCaseDataAction,
  caseDeletedSuccessAction,
  deleteAdvocateClientCaseAPI,
  resetCaseDeletedAction
} from 'actions/AdvocateClientCase/AdvocateClientCaseAction';
import { toast } from 'react-toastify';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps
}) => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [paramsData, setParamsData] = useState({});

  const toTabPage = rowData => {
    if (rowData.hasOwnProperty('encryptedUserId')) {
      dispatch(userDetailsAction(rowData));
      $('[data-rr-ui-event-key*="User Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="User Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
    } else if (rowData.hasOwnProperty('encryptedClientCode')) {
      localStorage.setItem(
        'EncryptedResponseClientCode',
        rowData.encryptedClientCode
      );
      dispatch(clientDetailsAction(rowData));
      $('[data-rr-ui-event-key*="Client Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Client Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
    } else if (rowData.hasOwnProperty('encryptedCaseId')) {
      dispatch(advocateClientCaseDataAction(rowData));
      $('[data-rr-ui-event-key*="Case Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Case Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem('EncryptedResponseCaseId', rowData.encryptedCaseId);
    } else if (!rowData.hasOwnProperty('encryptedCompanyCode')) {
      dispatch(advocateDetailsAction(rowData));
      $('[data-rr-ui-event-key*="Details"]').attr('disabled', false);
      $('[data-rr-ui-event-key*="Advocate Details"]').trigger('click');
      $('#btnSave').attr('disabled', true);
      localStorage.setItem(
        'EncryptedResponseAdvocateCode',
        rowData.encryptedAdvocateCode
      );
    }
  };
  const ModalPreview = encryptedCaseId => {
    setModalShow(true);
    setParamsData(encryptedCaseId);
  };

  var handleDeleteAdvocateClientCase = async encryptedCaseId => {
    var request = {
      EncryptedCaseId: encryptedCaseId
    };
    dispatch(
      deleteAdvocateClientCaseAPI(
        request,
        res => {
          toast.success(res?.data?.message, {
            theme: 'colored',
            autoClose: 10000
          });
          setModalShow(false);
          if(res?.data.status === 200){
            dispatch(caseDeletedSuccessAction(true));
          }
        },
        err => {
          toast.error(err?.data?.message, {
            theme: 'colored',
            autoClose: 10000
          });
          dispatch(resetCaseDeletedAction(false));
          setModalShow(false);
        }
      )
    );
  };

  return (
    <>
      {modalShow && paramsData && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete this client case detail?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDeleteAdvocateClientCase(paramsData)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <Table id="advanceTable" {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                className={rowClassName}
                {...row.getRowProps()}
                onDoubleClick={() => toTabPage(row.original)}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <>
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.column.id !== 'deleteCase' &&
                        cell.column.id !== 'caseStatus' &&
                        cell.column.id !== 'status' &&
                        cell.column.id !== 'approvalStatus' &&
                        cell.column.id !== 'poPrintStatus' &&
                        cell.column.id !== 'demandPrintStatus' &&
                        cell.column.id !== 'printStatus' &&
                        cell.column.id !== 'materialStatus' &&
                        cell.column.id !== 'poStatus' &&
                        cell.column.id !== 'invoiceStatus' &&
                        cell.column.id !== 'vendorInvoicePrintStatus' &&
                        cell.column.id !== 'receiveStatus' &&
                        cell.column.id !== 'demandStatus' &&
                        cell.column.id !== 'deleteCase' ? (
                          cell.render('Cell')
                        ) : cell.column.id == 'status' &&
                          cell.row.values.status == 'Active' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'status' &&
                          cell.row.values.status == 'Suspended' ? (
                          <Badge pill bg="secondary">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus ==
                            'Send for Verification' ? (
                          <Badge pill bg="warning">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'approvalStatus' &&
                          cell.row.values.approvalStatus == 'Suspended' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'materialStatus' &&
                          cell.row.values.materialStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'materialStatus' &&
                          cell.row.values.materialStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Rejected' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Hold' ? (
                          <Badge pill bg="warning">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Invoiced' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'poStatus' &&
                          cell.row.values.poStatus == 'Partially Paid' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus == 'CLOSED' ? (
                          <Badge pill bg="secondary">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus == 'LOST' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                          cell.row.values.caseStatus ==
                            'ONGOING' ? (
                          <Badge pill bg="warning">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'caseStatus' &&
                        cell.row.values.caseStatus == 'WON' ? (
                        <Badge pill bg="success">
                          {cell.render('Cell')}
                        </Badge>
                      ) : cell.column.id == 'printStatus' &&
                        
                         cell.column.id == 'receiveStatus' &&
                          cell.row.values.receiveStatus == 'Not Received' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'printStatus' &&
                          cell.row.values.printStatus == 'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf(cell.row.original.farmerCode, cell.row.original.vendorCode, cell.row.original.encryptedMaterialReceiptId)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'poPrintStatus' &&
                          cell.row.values.poPrintStatus == 'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf('', '', '', cell.row.original.encryptedPoNo)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'demandPrintStatus' &&
                          cell.row.values.demandStatus == 'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf('', '', '', '', '', cell.row.original.encryptedDemandNo)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'vendorInvoicePrintStatus' &&
                          cell.row.values.vendorInvoicePrintStatus ==
                            'Approved' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="print"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            // onClick={() => generatePdf('', '', '', '', cell.row.original.encryptedInvoiceHeaderCode)}
                          >
                            Print
                          </IconButton>
                        ) : cell.column.id == 'deleteCase' ? (
                          <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="trash"
                            iconClassName="me-1"
                            className="me-1 mb-2 mb-sm-0 hide-on-print"
                            onClick={() =>
                              ModalPreview(cell.row.original.encryptedCaseId)
                            }
                          ></IconButton>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Rejected' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Partially Paid' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'invoiceStatus' &&
                          cell.row.values.invoiceStatus == 'Fully Paid' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'demandStatus' &&
                          cell.row.values.demandStatus == 'Draft' ? (
                          <Badge pill bg="info">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'demandStatus' &&
                          cell.row.values.demandStatus == 'Cancelled' ? (
                          <Badge pill bg="danger">
                            {cell.render('Cell')}
                          </Badge>
                        ) : cell.column.id == 'demandStatus' &&
                          cell.row.values.demandStatus == 'Approved' ? (
                          <Badge pill bg="success">
                            {cell.render('Cell')}
                          </Badge>
                        ) : (
                          ''
                        )}
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

setTimeout(() => {
  $('#advanceTable tbody tr').click(function () {
    if ($(this).hasClass('row-selected')) {
      return;
    }
    $('#advanceTable tr.row-selected').removeClass('row-selected');
    $(this).addClass('row-selected');
  });
}, 1000);

AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable;
