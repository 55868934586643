import AdvocateClientCase from 'components/AdvocateClientCase/AdvocateClientCase';
import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const ADVOCATECLIENTCASE_LIST_REQUEST =
  'ADVOCATECLIENTCASE_LIST_REQUEST';
export const ADVOCATECLIENTCASE_LIST_SUCCESS =
  'ADVOCATECLIENTCASE_LIST_SUCCESS';
export const ADVOCATECLIENTCASE_LIST_FAILURE =
  'ADVOCATECLIENTCASE_LIST_FAILURE';
export const ADD_ADVOCATECLIENTCASE_REQUEST = 'ADD_ADVOCATECLIENTCASE_REQUEST';
export const ADD_ADVOCATECLIENTCASE_SUCCESS = 'ADD_ADVOCATECLIENTCASE_SUCCESS';
export const ADD_ADVOCATECLIENTCASE_FAILURE = 'ADD_ADVOCATECLIENTCASE_FAILURE';
export const CLIENTCASE_LIST_REQUEST = 'CLIENTCASE_LIST_REQUEST';
export const CLIENTCASE_LIST_SUCCESS = 'CLIENTCASE_LIST_SUCCESS';
export const CLIENTCASE_LIST_FAILURE = 'CLIENTCASE_LIST_FAILURE';
export const UPDATE_ADVOCATECLIENTCASE_REQUEST =
  'UPDATE_ADVOCATECLIENTCASE_REQUEST';
export const UPDATE_ADVOCATECLIENTCASE_SUCCESS =
  'ADD_ADVOCATECLIENTCASE_SUCCESS';
export const UPDATE_ADVOCATECLIENTCASE_FAILURE =
  'UPDATE_ADVOCATECLIENTCASE_SUCCESS';
export const DELETE_ADVOCATECLIENTCASE_REQUEST =
  'DELETE_ADVOCATECLIENTCASE_REQUEST';
export const DELETE_ADVOCATECLIENTCASE_SUCCESS =
  'DELETE_ADVOCATECLIENTCASE_SUCCESS';
export const DELETE_ADVOCATECLIENTCASE_FAILURE =
  'DELETE_ADVOCATECLIENTCASE_FAILURE';

export const advocateClientCaseListRequest = () => ({
  type: ADVOCATECLIENTCASE_LIST_REQUEST
});
export const advocateClientCaseListSuccess = payload => ({
  type: ADVOCATECLIENTCASE_LIST_SUCCESS,
  payload
});
export const advocateClientCaseListFailure = payload => ({
  type: ADVOCATECLIENTCASE_LIST_FAILURE,
  payload
});

export const addAdvocateClientCaseRequest = () => ({
  type: ADD_ADVOCATECLIENTCASE_REQUEST
});
export const addAdvocateClientCaseSuccess = payload => ({
  type: ADD_ADVOCATECLIENTCASE_SUCCESS,
  payload
});
export const addAdvocateClientCaseFailure = payload => ({
  type: ADD_ADVOCATECLIENTCASE_FAILURE,
  payload
});

export const updateAdvocateClientCaseRequest = () => ({
  type: UPDATE_ADVOCATECLIENTCASE_REQUEST
});
export const updateAdvocateClientCaseSuccess = payload => ({
  type: UPDATE_ADVOCATECLIENTCASE_SUCCESS,
  payload
});
export const updateAdvocateClientCaseFailure = payload => ({
  type: UPDATE_ADVOCATECLIENTCASE_FAILURE,
  payload
});

export const deleteAdvocateClientCaseRequest = () => ({
  type: DELETE_ADVOCATECLIENTCASE_REQUEST
});
export const deleteAdvocateClientCaseSuccess = payload => ({
  type: DELETE_ADVOCATECLIENTCASE_SUCCESS,
  payload
});
export const deleteAdvocateClientCaseFailure = payload => ({
  type: DELETE_ADVOCATECLIENTCASE_FAILURE,
  payload
});

export const clientCaseListRequest = () => ({
  type: CLIENTCASE_LIST_REQUEST
});
export const clientCaseListSuccess = payload => ({
  type: CLIENTCASE_LIST_SUCCESS,
  payload
});
export const clientCaseListFailure = payload => ({
  type: CLIENTCASE_LIST_FAILURE,
  payload
});

export const advocateClientCaseDataAction = payload => {
  return {
    type: 'ADVOCATECLIENTCASEDATA',
    payload
  };
};

export const advocateClientCaseErrorAction = payload => {
  return {
    type: 'ADVOCATECLIENTCASEDETAILSERROR',
    payload
  };
};

export const caseDeletedSuccessAction = payload => {
  return {
    type: 'DELETE_CASE_SUCCESS',
    payload
  };
};

export const resetCaseDeletedAction = payload => {
  return {
    type: 'RESET_CASE_DELETED',
    payload
  };
};

export const advocateClientCaseListAPI = (payload, successData, errorMSg) => {
  return dispatch => {
    dispatch(advocateClientCaseListRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/advocate-client-case-list'}`,
      payload
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(advocateClientCaseListSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(advocateClientCaseListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const addAdvocateClientCaseAPI = (payload, successData, errorMSg) => {
  return dispatch => {
    dispatch(addAdvocateClientCaseRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/add-advocate-client-case'}`,
      payload
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(addAdvocateClientCaseSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(addAdvocateClientCaseFailure('Something went wrong'));
        throw err;
      });
  };
};

export const updateAdvocateClientCaseAPI = (payload, successData, errorMSg) => {
  return dispatch => {
    dispatch(updateAdvocateClientCaseRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/update-advocate-client-case'}`,
      payload
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(updateAdvocateClientCaseSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(updateAdvocateClientCaseFailure('Something went wrong'));
        throw err;
      });
  };
};

export const clientCaseListAPI = (payload, successData, errorMSg) => {
  return dispatch => {
    dispatch(clientCaseListRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/client-case-list'}`,
      payload
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(clientCaseListSuccess(res));
        } else {
          errorMSg(res);
        }
      })
      .catch(err => {
        errorMSg(err?.res);
        dispatch(clientCaseListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const deleteAdvocateClientCaseAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(deleteAdvocateClientCaseRequest());
    return request(
      'DELETE',
      `${REACT_APP_API_URL + '/delete-advocate-client-case'}`,
      payload
    )
      .then(res => {
        if (res?.data.status === 200) {
          successData(res);
          dispatch(deleteAdvocateClientCaseSuccess(res));
        } else {
          errorMsg(res);
        }
      })
      .catch(err => {
        errorMsg(err?.res);
        dispatch(deleteAdvocateClientCaseFailure('Something went wrong'));
        throw err;
      });
  };
};
