import {
  MENU_HEADER_TREE_REQUEST,
  MENU_HEADER_TREE_SUCCESS,
  MENU_HEADER_TREE_FAILURE,
  ADVOCATE_USER_MENU_TREE_REQUEST,
  ADVOCATE_USER_MENU_TREE_SUCCESS,
  ADVOCATE_USER_MENU_TREE_FAILURE
} from '../../actions/SecurityMenuTree/SecurityMenuTreeAction';
const initialState = {
  headerMenu: [],
  advocateUserMenuTreeList: []
};

export default function SecurityMenuMenuTreeReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case MENU_HEADER_TREE_REQUEST:
      return {
        ...state
      };
    case MENU_HEADER_TREE_SUCCESS:
      return {
        ...state,
        headerMenu: action.payload
      };
    case MENU_HEADER_TREE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    case ADVOCATE_USER_MENU_TREE_REQUEST:
      return {
        ...state
      };
    case ADVOCATE_USER_MENU_TREE_SUCCESS:
      return {
        ...state,
        advocateUserMenuTreeList: action.payload
      };
    case ADVOCATE_USER_MENU_TREE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
