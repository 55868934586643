import request from 'components/Utils/Request';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const VALIDATE_CLIENT_REQUEST = 'VALIDATE_CLIENT_REQUEST';
export const VALIDATE_CLIENT_SUCCESS = 'VALIDATE_CLIENT_SUCCESS';
export const VALIDATE_CLIENT_FAILURE = 'VALIDATE_CLIENT_FAILURE';
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';
export const USER_REGISTRATION_REQUEST = 'USER_REGISTRATION_REQUEST';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE';
export const UPDATE_USER_DETAIL_REQUEST = 'UPDATE_USER_DETAIL_REQUEST';
export const UPDATE_USER_DETAIL_SUCCESS = 'UPDATE_USER_DETAIL_SUCCESS';
export const UPDATE_USER_DETAIL_FAILURE = 'UPDATE_USER_DETAIL_FAILURE';
const { REACT_APP_API_URL } = process.env;

//  login API
export const loginRequest = () => ({
  type: LOGIN_REQUEST
});
export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload
});
export const loginFailure = payload => ({
  type: LOGIN_FAILURE,
  payload
});

// forgot password
export const forgotPasswordRequest = () => ({
  type: FORGOT_PASSWORD_REQUEST
});
export const forgotPasswordSuccess = payload => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload
});
export const forgotPasswordFailure = payload => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload
});

// reset password
export const resetPasswordRequest = () => ({
  type: RESET_PASSWORD_REQUEST
});
export const resetPasswordSuccess = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload
});
export const resetPasswordFailure = payload => ({
  type: RESET_PASSWORD_FAILURE,
  payload
});

// Validate-Client
export const validateClientRequest = () => ({
  type: VALIDATE_CLIENT_REQUEST
});
export const validateClientSuccess = payload => ({
  type: VALIDATE_CLIENT_SUCCESS,
  payload
});
export const validateClientFailure = payload => ({
  type: VALIDATE_CLIENT_FAILURE,
  payload
});

// user-list
export const userListRequest = payload => ({
  type: USER_LIST_REQUEST
});
export const userListSuccess = payload => ({
  type: USER_LIST_SUCCESS,
  payload
});
export const userListFailure = payload => ({
  type: USER_LIST_FAILURE,
  payload
});

// add-user-registration
export const userRegistrationRequest = payload => ({
  type: USER_REGISTRATION_REQUEST
});
export const userRegistrationSuccess = payload => ({
  type: USER_LIST_SUCCESS,
  payload
});
export const userRegistrationFailure = payload => ({
  type: USER_REGISTRATION_FAILURE,
  payload
});

// update-user-detail
export const updateUserDetailRequest = payload => ({
  type: UPDATE_USER_DETAIL_REQUEST
});
export const updateUserDetailSuccess = payload => ({
  type: UPDATE_USER_DETAIL_SUCCESS,
  payload
});
export const updateUserDetailFailure = payload => ({
  type: UPDATE_USER_DETAIL_FAILURE,
  payload
});

export const userDetailsErrorAction = payload => {
  return {
    type: 'USERDETAILSERROR',
    payload
  };
};

export const userDetailsAction = payload => {
  return {
    type: 'USERDETAILS',
    payload
  };
};

export const userDataAction = payload => {
  return {
    type: 'USERDATA',
    payload
  };
};

export const loginAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(loginRequest());
    return request('POST', `${REACT_APP_API_URL + '/login'}`, payload)
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(loginSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(loginFailure('Something went wrong'));
        throw error;
      });
  };
};

export const forgotPasswordAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(forgotPasswordRequest());
    const url = `${REACT_APP_API_URL}/forgot-password/${payload}`;
    return request('POST', url)
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(loginSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(loginFailure('Something went wrong'));
        throw error;
      });
  };
};

export const resetPasswordAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(loginRequest());
    return request('POST', `${REACT_APP_API_URL + '/reset-password'}`, payload)
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(loginSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(loginFailure('Something went wrong'));
        throw error;
      });
  };
};

export const validateClientAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(forgotPasswordRequest());
    const url = `${REACT_APP_API_URL}/validate-client/${payload}`;
    return request('POST', url)
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(loginSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(loginFailure('Something went wrong'));
        throw error;
      });
  };
};

export const userListAPI = (payload, successData, ErrMsg) => {
  return dispatch => {
    dispatch(userListRequest());
    const url = `${REACT_APP_API_URL}/user-list`;
    return request('POST', url, payload)
      .then(res => {
        if (res?.data.status == 200) {
          successData(res);
          dispatch(userListSuccess(res));
        } else {
          ErrMsg(res);
        }
      })
      .catch(err => {
        ErrMsg(err.res);
        dispatch(userListFailure('Something went wrong'));
        throw err;
      });
  };
};

export const userRegistrationAPI = (payload, successCb, errorCB) => {
  return dispatch => {
    dispatch(userRegistrationRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/user-registration'}`,
      payload
    )
      .then(response => {
        if (response?.data?.status === 200) {
          successCb(response);
          dispatch(userRegistrationSuccess(response));
        } else {
          errorCB(response);
        }
      })
      .catch(error => {
        errorCB(error?.response);
        dispatch(userRegistrationFailure('Something Went wrong'));
        throw error;
      });
  };
};

export const updateUserDetailAPI = (payload, successData, errorMsg) => {
  return dispatch => {
    dispatch(updateUserDetailRequest());
    return request(
      'POST',
      `${REACT_APP_API_URL + '/update-user-detail'}`,
      payload
    )
      .then(response => {
        if (response?.data?.status === 200) {
          successData(response);
          dispatch(updateUserDetailSuccess(response));
        } else {
          errorMsg(response);
        }
      })
      .catch(error => {
        errorMsg(error?.response);
        dispatch(updateUserDetailFailure('Something Went Wrong'));
        throw error;
      });
  };
};