import axios from 'axios';
const request = (method = "get", url = "/", payload, params) => {
    const options = {
      method,
      url,
      data: payload !== undefined && payload,
      params: params,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   },
    };

    return new Promise((resolve, reject) => {
      axios(options, payload)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
}

export default request;